import axios from 'axios';

const DadataAPI = {
  kladr_id: null,
  defaultData: {
    count: 5,
    parts: ['NAME'],
    query: '',
  },
  defaultOptions: {
    url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + process.env.VUE_APP_DADATA_KEY,
    },
    data: {},
  },
  async search(clientData) {
    try {
      const response = await axios.post(
        'https://i-assistant.online/api/search',
        { clientData }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getKladrId(userIP = null) {
    if (userIP !== null && userIP.length) {
      try {
        const response = await axios({
          url:
            'https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address',
          method: 'get',
          headers: this.defaultOptions.headers,
          data: {
            ip: userIP,
          },
        });

        if ('data' in response.data.location) {
          this.kladr_id = response.data.location.data.kladr_id;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },
  formatResultFMSUnit(suggestions) {
    return suggestions.map((suggestion) => {
      suggestion.value = suggestion.data.code;
      return suggestion;
    });
  },
  getLocation(type, location) {
    let data = {};

    switch (type) {
      case 'addrCity':
        if (
          location.country_iso_code != null &&
          location.country_iso_code !== undefined
        ) {
          data.country_iso_code = location.country_iso_code;
        }

        if (
          location.region_fias_id != null &&
          location.region_fias_id !== undefined
        ) {
          data.region_fias_id = location.region_fias_id;
        }

        if (
          location.region_iso_code != null &&
          location.region_iso_code !== undefined
        ) {
          data.region_iso_code = location.region_iso_code;
        }
        break;

      case 'addrStreet':
        if (
          location.country_iso_code != null &&
          location.country_iso_code !== undefined
        ) {
          data.country_iso_code = location.country_iso_code;
        }

        if (
          location.region_fias_id != null &&
          location.region_fias_id !== undefined
        ) {
          data.region_fias_id = location.region_fias_id;
        }

        if (
          location.region_iso_code != null &&
          location.region_iso_code !== undefined
        ) {
          data.region_iso_code = location.region_iso_code;
        }

        if (
          location.city_fias_id != null &&
          location.city_fias_id !== undefined
        ) {
          data.city_fias_id = location.city_fias_id;
        }
        break;

      case 'addrHouse':
        if (
          location.country_iso_code != null &&
          location.country_iso_code !== undefined
        ) {
          data.country_iso_code = location.country_iso_code;
        }

        if (
          location.region_fias_id != null &&
          location.region_fias_id !== undefined
        ) {
          data.region_fias_id = location.region_fias_id;
        }

        if (
          location.region_iso_code != null &&
          location.region_iso_code !== undefined
        ) {
          data.region_iso_code = location.region_iso_code;
        }

        if (
          location.city_fias_id != null &&
          location.city_fias_id !== undefined
        ) {
          data.city_fias_id = location.city_fias_id;
        }

        if (
          location.street_fias_id != null &&
          location.street_fias_id !== undefined
        ) {
          data.street_fias_id = location.street_fias_id;
        }
        break;

      default:
        break;
    }

    return data;
  },
  async getFIO({ value, type, gender = null }) {
    switch (type) {
      case 'lastName':
        type = 'SURNAME';
        break;

      case 'firstName':
        type = 'NAME';
        break;

      case 'patronymic':
        type = 'PATRONYMIC';
        break;

      default:
        break;
    }

    const data = {
      count: 5,
      parts: [type],
      query: value,
    };

    if (gender !== null && gender.length) {
      data.gender = Number(gender) ? 'FEMALE' : 'MALE';
    }

    const options = {
      ...this.defaultOptions,
      ...{
        data,
        url: this.defaultOptions.url + 'fio',
      },
    };

    return await this.dadataRequest(options);
  },
  async getFMSUnit({ value }) {
    const data = {
      count: 5,
      query: value,
    };

    const options = {
      ...this.defaultOptions,
      ...{
        data,
        url: this.defaultOptions.url + 'fms_unit',
      },
    };

    return this.formatResultFMSUnit(await this.dadataRequest(options));
  },
  async getAddress({ value, type, location }) {
    const data = {
      count: 5,
      parts: ['ADDRESS'],
      query: value,
    };
    const locationData = this.getLocation(type, location);

    switch (type) {
      case 'addrRegion':
        data.from_bound = { value: 'region' };
        data.to_bound = { value: 'area' };
        break;

      case 'addrCity':
        if (Object.keys(locationData).length) {
          data.restrict_value = true;
          data.locations = [locationData];
        }

        data.from_bound = { value: 'city' };
        data.to_bound = { value: 'settlement' };
        break;

      case 'addrStreet':
        if (Object.keys(locationData).length) {
          data.restrict_value = true;
          data.locations = [locationData];
        }

        data.from_bound = { value: 'street' };
        data.to_bound = { value: 'street' };
        break;

      case 'addrHouse':
        if (Object.keys(locationData).length) {
          data.restrict_value = true;
          data.locations = [locationData];
        }

        data.from_bound = { value: 'house' };
        break;

      default:
        break;
    }

    if (this.kladr_id !== null && this.kladr_id.length) {
      data.locations_boost = [{ kladr_id: this.kladr_id }];
    }

    const options = {
      ...this.defaultOptions,
      ...{
        data,
        url: this.defaultOptions.url + 'address',
      },
    };

    return await this.dadataRequest(options);
  },
  async getCarBrand({ value }) {
    const data = {
      count: 5,
      query: value,
    };

    const options = {
      ...this.defaultOptions,
      ...{
        data,
        url: this.defaultOptions.url + 'car_brand',
      },
    };

    return await this.dadataRequest(options);
  },
  async getINN({ value }) {
    const data = {
      count: 5,
      query: value,
    };

    const options = {
      ...this.defaultOptions,
      ...{
        data,
        url: this.defaultOptions.url + 'party',
      },
    };

    return await this.dadataRequest(options);
  },
  async dadataRequest(options) {
    try {
      const response = await axios(options);
      return response.data.suggestions;
    } catch (error) {
      // console.error(error);
      return [];
    }
  },
};

export default DadataAPI;
